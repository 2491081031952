import { FC, useState, useEffect, useReducer, Fragment, MouseEvent } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import { asText } from "@prismicio/client";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import * as DOMPurify from 'isomorphic-dompurify';
import {
  Box,
  HStack,
  Spacer,
  Text,
  VStack,
  Flex,
  Button,
  useBreakpointValue,
  StackProps,
} from "@chakra-ui/react";

import { getColor } from "../utils";
import { parseOneLinerLogicalText, shouldShowLogicalText } from "../../utils";
import { LogicalText } from "../../types";
import { ClickInfoNewPricing } from "src/lib/event/types";
import { onClickNewAccountNewPricing } from "src/lib/event";
import patchSignupURL, { isSignupURL } from "src/atoms/PrismicLink/lib/patchSignupURL";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";

const Card: FC<
  StackProps & { item: Gatsby.PageBlockPricingCardsItemFragment }
> = ({ item, ...rest }) => {
  if (!item) return null;

  const visitorContext = useVisitorContext();
  const location = useLocation();
  const { pathname, search } = location;

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    navbar: true,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const currentParams = queryString.parse(search);
  const cycle = (currentParams["cycle"] as string) ?? "";
  const isMonthly = cycle === "mensuel";

  const shouldShow = (str: string) => {
    const output: LogicalText = parseOneLinerLogicalText(str);

    return shouldShowLogicalText(pathname, search, output);
  };

  const {
    is_displayed: isDisplayed,
    is_highlighted_bis: isHighlighted,
    badge_bis: badge,
    second_badge: secondBadge,
    badge_same_color: badgeSameColor,
    color,
    cta_is_secondary: ctaIsSecondary,
    cta_text_desktop_bis: ctaTextDesktop,
    cta_text_mobile_bis: ctaTextMobile,
    cta_text_desktop_monthly_to_yearly: ctaTextDesktopMonthlyToYearly,
    cta_text_mobile_monthly_to_yearly: ctaTextMobileMonthlyToYearly,
    cta_link_monthly: ctaLinkMonthly,
    cta_link_yearly: ctaLinkYearly,
    plan_name: planName,
    plan_subtitle: planSubtitle,
    price_subtitle_1: priceSubtitle1,
    price_subtitle_2: priceSubtitle2,
    price_value_bis: priceValue,
    price_text_suffix_bis: priceTextSuffix,
    multi_line_usp: multiLineUSP
  } = item;

  const shouldShowCard = shouldShow(isDisplayed);
  if (!shouldShowCard) {
    return null;
  }

  const width = { base: "300px", md: "300px", xl: "320px", "2xl": "346px" };

  // TODO: rename renderPrice
  const renderPrice = (price: any, props = {}, shouldSanitize = false, onlyText = false) => {
    const allTexts = asText(price.raw, "\n")?.split("\n");
    const matchingLogicalText = allTexts?.find((logicalText: string) => {
      const output: LogicalText = parseOneLinerLogicalText(logicalText);

      return shouldShowLogicalText(pathname, search, output);
    });

    if (matchingLogicalText) {
      if (shouldSanitize) {
        const canSanitize = DOMPurify.isSupported;
        if (!canSanitize) { return null; }
        const textSanitized = DOMPurify.sanitize(parseOneLinerLogicalText(matchingLogicalText).text || "", { USE_PROFILES: { html: true } });
        return <Box
          {...props}
          dangerouslySetInnerHTML={{ __html: textSanitized }}
        />
      }

      if (onlyText) {
        return parseOneLinerLogicalText(matchingLogicalText).text;
      }

      return <Box
        {...props}
      >{parseOneLinerLogicalText(matchingLogicalText).text}</Box>
    }

    return null;
  };

  // TODO: combine badges into one
  return (
    <VStack {...rest}>
      <VStack minWidth={width} maxWidth={width}
        border="1px"
        borderRadius="16px 16px 0 0"
        borderColor={shouldShow(isHighlighted) ? "black" : "grey-300"}
        color="grey-0"
        paddingX={{ base: "space-16", md: "space-16", xl: "space-40" }}
        paddingTop={{ base: "space-32", md: "space-40" }}
        paddingBottom={{ base: "space-16", md: isMonthly ? "space-40" : "space-32" }}
        position="relative"
      >
        {(badge?.raw?.length > 0 && renderPrice(badge)) || (secondBadge?.raw?.length > 0 && renderPrice(secondBadge)) || (badgeSameColor?.raw?.length > 0 && renderPrice(badgeSameColor))
          ? <HStack
            position="absolute"
            top="-18px"
            left="37px"
          >
            {(badge?.raw?.length > 0 && renderPrice(badge)) ? <HStack
              borderRadius="6px"
              paddingX="space-12"
              paddingY="space-8"
              fontSize="font-14"
              lineHeight={1.42}
              fontWeight="400"
              backgroundColor="#FCF0B6"
            >
              <ShiIcon name="sparkles" boxSize="14px" />
              <Text>{renderPrice(badge)}</Text>
            </HStack> : null}
            {secondBadge?.raw?.length > 0 && renderPrice(secondBadge) ? <HStack
              borderRadius="6px"
              paddingX="space-12"
              paddingY="space-8"
              fontSize="font-14"
              lineHeight={1.42}
              fontWeight="400"
              backgroundColor="grey-300"
            >
              <ShiIcon name="sparkle-single" boxSize="14px" marginTop="-2px" />
              <Text>{renderPrice(secondBadge)}</Text>
            </HStack> : null}
            {(badgeSameColor?.raw?.length > 0 && renderPrice(badgeSameColor)) ? <HStack
              borderRadius="6px"
              paddingX="space-12"
              paddingY="space-8"
              fontSize="font-14"
              lineHeight={1.42}
              fontWeight="400"
              color={["#2A93F4", "grey-0"].includes(getColor(color)) ? "white" : "grey-0"}
              backgroundColor={getColor(color)}
            >
              <Text>{renderPrice(badgeSameColor)}</Text>
            </HStack> : null}
          </HStack>
          : <HStack
            position="absolute"
            top="-18px"
            left="37px"
          />
        }
        <Box>
          <HStack spacing="space-16">
            <Box
              minWidth="14px"
              maxWidth="14px"
              minHeight="14px"
              maxHeight="14px"
              backgroundColor={getColor(color)}
              borderRadius="4px"
            />

            <Box
              lineHeight="shortest"
              fontSize={{ base: "font-28", md: "font-30" }}
              textAlign="left"
            >
              {asText(planName.raw, "\n")?.split("\n").map((a: any) => {
                // TODO: let's make this a util
                if (!a) {
                  return null;
                }

                const output: LogicalText = parseOneLinerLogicalText(a);
                if (shouldShowLogicalText(pathname, search, output)) {
                  return <Text key={output.text}>{output.text}</Text>;
                }

                return null;
              })}
            </Box>
          </HStack>
          {renderPrice(planSubtitle, {
            lineHeight: "base",
            fontSize: { base: "font-14", md: "font-16" },
            textAlign: "left",
            marginTop: "4px",
            marginBottom: { base: "space-12", md: "space-24" }
          })}
          <HStack>
            {renderPrice(priceValue, {
              fontSize: { base: "font-28", md: "font-40" },
              lineHeight: "shortest"
            })}
            {renderPrice(priceTextSuffix, {
              fontSize: { base: "font-28", md: "26px" },
              lineHeight: 1
            })}
          </HStack>
          {renderPrice(priceSubtitle1, {
            textAlign: "left",
            fontSize: "12px",
            marginTop: "space-4"
          }, true)}
          {renderPrice(priceSubtitle2, {
            textAlign: "left",
            color: "#56AA41",
            fontSize: "12px",
            paddingBottom: "space-8"
          })}
          <Box marginBottom="space-16" />
          <Button
            color={ctaIsSecondary ? "grey-0" : "white"}
            width="100%"
            marginTop="10px"
            fontWeight={{ base: "500", md: "400" }}
            fontSize={{ base: "font-14", md: "15px" }}
            backgroundColor={ctaIsSecondary ? "grey-200" : "grey-0"}
            _hover={{
              color: "white",
              backgroundColor: ctaIsSecondary ? "grey-600" : "grey-700"
            }}
            onClick={(e) => {
              const toUrlTemp = isMonthly ? ctaLinkMonthly?.url : ctaLinkYearly?.url;
              const clickInfoNewPricing: ClickInfoNewPricing = {
                label: renderPrice(isMobile ? ctaTextMobile : ctaTextDesktop, {}, false, true) || '',
                platform: isMobile ? 'web-mobile' : 'web-desktop',
                block: 'block_pricing_cards',
                fromLocation: location,
                plan: renderPrice(planName, {}, false, true),
                toUrl: isSignupURL(toUrlTemp) ? patchSignupURL(visitorContext, toUrlTemp) : toUrlTemp
              };
  
              onClickNewAccountNewPricing(e, clickInfoNewPricing)
            }}
          >
            {renderPrice(isMobile ? ctaTextMobile : ctaTextDesktop)}
          </Button>
          {isMonthly && renderPrice(isMobile ? ctaTextMobileMonthlyToYearly : ctaTextDesktopMonthlyToYearly) ? <Button
            variant="secondary"
            fontSize="12px"
            fontWeight="400"
            color="grey-0"
            borderBottom="1px solid"
            marginTop="space-16"
            paddingBottom={{ base: "space-2", md: "space-2" }}
            onClick={(e) => {
              const clickInfoNewPricing: ClickInfoNewPricing = {
                label: `${renderPrice(isMobile ? ctaTextMobileMonthlyToYearly : ctaTextDesktopMonthlyToYearly, {}, false, true)} ->` || '',
                platform: isMobile ? 'web-mobile' : 'web-desktop',
                block: 'block_pricing_cards',
                fromLocation: location,
                plan: renderPrice(planName, {}, false, true),
                toUrl: isSignupURL(ctaLinkMonthly?.url) ? patchSignupURL(visitorContext, ctaLinkMonthly?.url) : ctaLinkMonthly?.url
              };
  
              onClickNewAccountNewPricing(e, clickInfoNewPricing)
            }}
          >
            {`${renderPrice(isMobile ? ctaTextMobileMonthlyToYearly : ctaTextDesktopMonthlyToYearly, {}, false, true)} ->`}
          </Button> : (isMonthly ? <Box height="32px"></Box> : null)}
        </Box>
      </VStack>
      <VStack minWidth={width} maxWidth={width}
        minHeight={{ base: "280px", md: "330px" }} // TODO: improve this part for dynamic USP
        border="1px"
        borderTop="0px"
        borderRadius="0 0 16px 16px"
        borderColor={shouldShow(isHighlighted) ? "black" : "grey-300"}
        color="grey-0"
        paddingX={{ base: "space-16", md: "space-16", xl: "space-40" }}
        paddingTop={{ base: "space-16", md: "space-32" }}
        paddingBottom={{ base: "space-16", md: "space-40" }}
        position="relative"
        marginTop="0px !important"
      >
        <Box
        // border="1px solid red"
        mt={{ base: "10px", md: 0 }}
        >
          <HStack
            fontSize="font-14"
            lineHeight="30px"
            fontWeight="500"
          >
            <Box>{asText(planName.raw, "\n")?.split("\n").map((a: any) => {
              // TODO: let's make this a util
              if (!a) {
                return null;
              }

              const output: LogicalText = parseOneLinerLogicalText(a);
              if (shouldShowLogicalText(pathname, search, output)) {
                return <Text key={output.text}>{`Avantages ${output.text}`}</Text>;
              }

              return null;
            })}</Box>
          </HStack>
          <Box marginBottom="space-16" />
          <VStack
            alignItems="start"
            spacing="space-12"
          >{asText(multiLineUSP.raw, "\n")?.split("\n").map((a: any) => {
            if (!a) {
              return null;
            }

            const output: LogicalText = parseOneLinerLogicalText(a);
            if (shouldShowLogicalText(pathname, search, output)) {
              return (
                <Flex textAlign="left" key={output.text}>
                  <ShiIcon name="check" boxSize="14px" marginTop="4px" color="red" />
                  <Text marginLeft="space-8" fontSize="14px" lineHeight="22px">{output.text}</Text>
                </Flex>
              );
            }

            return null;
          })}</VStack>
        </Box>
      </VStack>
    </VStack>
  );
};

export default Card;
