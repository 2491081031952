import { FC, useState, useEffect } from "react";
import {
  Box,
  Tooltip
} from "@chakra-ui/react";
import { ShiIcon } from "src/atoms";

const SelfClosingTooltip: FC<{
  tooltipText?: string | null
}> = ({
  tooltipText
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const autoCloseTime = 3000;

    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setIsOpen(false);
        }, autoCloseTime);

        return () => clearTimeout(timer);
      }
    }, [isOpen]);

    if (!tooltipText) {
      return null;
    }

    return (
      <Tooltip
        label={tooltipText}
        backgroundColor="grey-200"
        color="grey-600"
        fontWeight="normal"
        lineHeight={1.42}
        padding="space-16"
        borderRadius="md"
        shadow="none"
        placement="bottom-end"
        zIndex="15"
        maxWidth="252px"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box
          onMouseEnter={() => setIsOpen(true)}
        ><ShiIcon name="infocircle" /></Box>
      </Tooltip>
    );
  };

export default SelfClosingTooltip;
