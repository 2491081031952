import { KEYWORD_ALLOWLIST, KEYWORD_BLOCKLIST, PATTERN_ALLOWLIST, PATTERN_BLOCKLIST } from "./constants";
import { LogicalText, Slice } from "./types";
import queryString from "query-string";

const pageFragmentTypes = [
  "PrismicCollectionRatings",
  "PrismicCollectionPricings",
  "PrismicCollectionTable",
  "PrismicCollectionShinestartBlocks",
  "PrismicCollectionPresseBlocks",
  "PrismicCollectionIframe",
];

const sliceBgColors: { [key: string]: string } = {
  block_columns: "greyLight-4",
  block_title: "greyLight-4",
  block_persona: "blue-0",
  block_creation_redirection: "blue-0",
  block_animated_testimonial: "greyLight-4",
  block_plans_presentation: "greyLight-4",
  block_trio_offer: "blue-0"
};

const NON_PADDING_TYPES = ["sub_pages", "block_animated_testimonial"];

// Returns the URL with a leading slash and trailing slash for the pathname if applicable (if not a HP)
const addSlashesToPathname = (str: string) => {
  if (!str) {
    return str;
  }

  const [pathname, queryParams] = str.split('?');

  if (!pathname) {
    return `?${queryParams}`;
  }

  const includePrefix = !pathname.startsWith("/") && !pathname.startsWith("*");
  const includeSuffix = !pathname.endsWith("/") && !pathname.endsWith("*");

  const newPathname = pathname === "/"
    ? pathname
    : `${includePrefix ? "/" : ""}${pathname}${includeSuffix ? "/" : ""}`

  return `${newPathname}${queryParams ? `?${queryParams}` : ""}`;
};

export const getBackgroundColor = (currentSlice: Slice | null, nextSlice: Slice | null) => {
  if (!currentSlice) return null;
  if (currentSlice.slice_type === "block_title" && nextSlice && nextSlice.slice_type === "block_pricing_table_header") {
    return null;
  }

  const typename =
    currentSlice.slice_type !== "block_relation"
      ? currentSlice?.slice_type
      : currentSlice?.primary?.relation?.document?.__typename;

  if (typename === "block_columns") {
    // block columns have a specific spacing exceptions depending on its background color
    if (currentSlice.primary?.has_colored_background === false) {
      return null;
    }
  }

  if (typename === "block_plans_presentation") {
    // block plans presentation have a specific spacing exceptions depending on its background color
    if (currentSlice.primary?.is_header === true) {
      return null;
    }
  }

  const bgColor: string | null = sliceBgColors[typename] || null;

  return bgColor;
};

export const checkExtraPadding = (
  slice: Slice | null,
  nextSlice?: Slice | null,
  is_block_relation?: boolean,
) => {

  if (slice?.slice_type === "block_trio_offer") {
    return false
  }

  if (slice?.slice_type === "block_tabs") {
    return false
  }

  if (slice?.slice_type === "block_pricing_cards") {
    return false
  }

  if (slice?.slice_type === "block_title" && nextSlice && nextSlice.slice_type === "block_pricing_table_header") {
    return false;
  }

  if (!nextSlice) return true;

  const currentBackground = getBackgroundColor(slice);

  const nextBackground = getBackgroundColor(nextSlice!);

  if (is_block_relation || (slice && NON_PADDING_TYPES.includes(slice?.slice_type))) {
    return false
  }

  return currentBackground !== nextBackground ? true : false;
};

export const getSliceType = (slice?: Slice | null) => {
  if (!slice) return null;

  let currentSlice = slice;

  if (!slice.primary) return slice; // this is a case when page fragment is used inside page fragment which isn't supported

  if (slice.slice_type === "block_relation") {
    if (
      pageFragmentTypes.includes(slice?.primary?.relation.document?.__typename)
    ) {
      return slice; // just return the block relation, as its reference is a custom type, not a slice
    }

    currentSlice =
      currentSlice.primary.relation.document?.data?.body &&
      currentSlice.primary.relation.document.data?.body[0];
  }

  return currentSlice;
};

// Note: different function prototype than ramda because of optional value from the CMS
export const clamp = (lower: number, upper: number, defaultValue: number, value?: number) => {
  if (typeof value !== 'number') {
    return defaultValue;
  }

  if (value < lower) {
    return lower;
  }

  if (value > upper) {
    return upper;
  }

  return value;
};

export const isHubspotFormSlice = (slice?: Slice | null) => {
  if (!slice) {
    return false;
  }

  return ['block_hubspot_form', 'block_embed_hubspot_form'].includes(slice.slice_type);
};

export const isEmbedHubspotFormSlice = (slice?: Slice | null) => {
  if (!slice) {
    return false;
  }

  return slice.slice_type === 'block_embed_hubspot_form';
};

export const shouldDisplayBlock = (experimentVariant?: string) => {
  if (!experimentVariant || experimentVariant === 'all') {
    return true;
  }

  switch (process.env.GATSBY_BRANCH) {
    case "master": return experimentVariant === "a";
    case "mbster": return experimentVariant === "b";
    default: return true;
  }
};

// Returns whether required search params are included in the current search params
const verifySearchCondition = (requiredSearch: string, currentSearch: string) => {
  const requiredQuery = queryString.parse(requiredSearch);
  const currentQuery = queryString.parse(currentSearch);

  const requiredKeys = Object.keys(requiredQuery);

  const isValid = requiredKeys.every((key: string) => {
    return currentQuery[key] === requiredQuery[key];
  });

  return isValid;
};

// Returns whether a logical text should be shown based on the pathname and search
const shouldShowLogicalText = (pathname: string, search: string, logicalText: LogicalText) => {
  const sanitizedPathname = addSlashesToPathname(pathname);
  if (logicalText.allowlist.length === 0 && logicalText.blocklist.length === 0) {
    return true;
  }

  // Note: unit tests for this function are contained within shouldShowLogicalText's
  const verifyAtLeastOneMatch = (list: string[], currentPathname: string, currentSearch: string) => {
    return list.some((element: string) => {
      const [str, search] = element.split('?');
      const isValidSearch = verifySearchCondition(search, currentSearch);

      if (!str) {
        return isValidSearch;
      }

      if (!str.includes('*')) {
        return str === currentPathname && isValidSearch;
      }

      if (str.startsWith('*') && str.endsWith('*')) {
        const newStr = str.slice(1, str.length - 1);
        return currentPathname.includes(newStr) && isValidSearch;
      }

      if (str.startsWith('*')) {
        const newStr = str.slice(1);
        return currentPathname.endsWith(newStr) && isValidSearch;
      }

      if (str.endsWith('*')) {
        const newStr = str.slice(0, str.length - 1);
        return currentPathname.startsWith(newStr) && isValidSearch;
      }

      return false;
    });
  };

  // In case of conflicts between both lists, allowlist takes precedence over blocklist
  const { allowlist, blocklist } = logicalText;
  if (logicalText.allowlist.length > 0) {
    const isAllowed = verifyAtLeastOneMatch(allowlist, sanitizedPathname, search);

    return isAllowed;
  }

  const isBlocked = verifyAtLeastOneMatch(blocklist, sanitizedPathname, search);

  return !isBlocked;
};

// Given a templated list it returns the matches e.g. `[allow:a,b] [block:b,c,d]` with `allow` would return `[a, b]`
const parseList = (parameter: "allow" | "block", str: string | undefined | null): string[] => {
  if (!str) {
    return [] as string[];
  }

  const regex = new RegExp(`\\[${parameter}:(.*?)\\]`);

  const match = str.match(regex);

  if (match) {
    const listContent = match[1];
    if (!listContent) {
      return [] as string[];
    }

    return listContent.split(",").map((str: string) => str.trim()).filter((str: string) => !!str);
  } else {
    return [] as string[];
  }
};

// Returns the same list by applying leading and trailing slashes and removing duplicates
const sanitizeList = (list: string[]) => {
  if (list.length === 0) {
    return list;
  }

  const finalList: string[] = [];
  list.forEach((element: string) => {
    const sanitizedElement = addSlashesToPathname(element);

    if (!finalList.includes(sanitizedElement)) {
      finalList.push(sanitizedElement);
    }
  });

  return finalList;
};

// Returns reduced blocklist: in case of conflicts between both lists, allowlist takes precedence over blocklist
const reduceBlocklist = (allowlist: string[], blocklist: string[]) => {
  if (allowlist.length === 0 || blocklist.length === 0) {
    return blocklist;
  }

  return [] as string[];
};

// Given a templated string it returns a structure that can be exploited for logic on it
// e.g. `Some text [allow:a,b] [block:b,c,d]` would return a structure extracting each of those 3 elements
const parseOneLinerLogicalText = (str: string | undefined | null): LogicalText => {
  const defaultOutput: LogicalText = {
    text: str,
    allowlist: [],
    blocklist: []
  };

  if (!str) {
    return defaultOutput;
  }

  const indexAllowlist = str.indexOf(PATTERN_ALLOWLIST);
  const indexBlocklist = str.indexOf(PATTERN_BLOCKLIST);

  const hasNoAllowlist = indexAllowlist === -1;
  const hasNoBlocklist = indexBlocklist === -1;

  if (hasNoAllowlist && hasNoBlocklist) {
    return defaultOutput;
  }

  const indexPatternMin = getMin(indexAllowlist, indexBlocklist, -1);

  const text = str.slice(0, indexPatternMin).trim();
  const patternStr = str.slice(indexPatternMin);

  const allowlist = parseList(KEYWORD_ALLOWLIST, patternStr);
  const blocklist = parseList(KEYWORD_BLOCKLIST, patternStr);

  const sanitizedAllowlist = sanitizeList(allowlist);
  const sanitizedBlocklist = sanitizeList(blocklist);

  const finalBlocklist = reduceBlocklist(sanitizedAllowlist, sanitizedBlocklist);

  return {
    text,
    allowlist: sanitizedAllowlist,
    blocklist: finalBlocklist
  };
};

// Returns the minimum of two numbers while ignoring a given number (typically -1 which is index not found)
const getMin = (a: number, b: number, ignore: number) => {
  if (a === ignore) {
    return b;
  }

  if (b === ignore) {
    return a;
  }

  return a < b ? a : b;
};

export { addSlashesToPathname, getMin, parseList, parseOneLinerLogicalText, reduceBlocklist, sanitizeList, shouldShowLogicalText, verifySearchCondition };
