import { FC, useState, useEffect, useReducer } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import { useLocation } from "@reach/router";
import paragraph from "src/lib/renderer/elements/paragraph";
import { heading1, heading2, heading3, heading4, heading5, heading6 } from "src/lib/renderer/elements/headings";
import {
  Box,
  HStack,
  Text,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { SvgOrImg } from "src/components/SvgOrImg";
import { Element } from "@prismicio/react";

const BlockTextualColumns: FC<Gatsby.PageBlockTextualColumnsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false)
  }, []);

  const location = useLocation();

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    forceUpdate();
  }, [location.search]);

  const {
    content
  } = primary;

  const renderItem = (item: any, index: number, columnIndex: number) => {
    const {
      icon,
      is_second_column: isSecondColumn,
      text,
    } = item;

    if (columnIndex === 0 && isSecondColumn) return null;
    if (columnIndex === 1 && !isSecondColumn) return null;

    return (
      <HStack key={text}>
        <Box display="inline-block" marginRight="space-8">
          <SvgOrImg
            additionalBoxProps={{
              maxW: { base: "16px" },
              maxH: { base: "16px" }
            }}
            imageFile={icon?.document?.data?.image_file}
            size={16}
          />
        </Box>
        <Text
          fontSize="font-16"
          lineHeight="22px"
          textAlign="left"
        >{text}</Text>
      </HStack>
    );
  }

  const commonProps = {
    color: "white",
    fontFamily: "body",
    alignSelf: "center",
    marginBottom: "0px"
  };

  return (
    <BlockContainer w="full" key={update} filter={isBlurred ? 'blur(3px)' : ''}
      paddingY="0px !important"
    >
      <VStack w="full"
        backgroundColor="grey-0"
        borderRadius="lg"
        color="white"
        padding={{ base: "space-32", md: "space-60" }}
      >
        <Flex gap="space-32" justifyContent="space-between"
          w="full"
          align="center"
          justify="flex-start"
          alignItems="center"
          direction={{ base: "column", md: "row" }}
        >
          <Box
            flex="1"
            align="left"
          >
            <Box maxWidth="360px">
              <Renderer
                field={content}
                overrides={{
                  [Element.paragraph]: (args: any) =>
                    paragraph({
                      ...args,
                      overrideProps: {
                        ...commonProps
                      },
                    }),
                  [Element.heading1]: (args: any) =>
                    heading1({
                      ...args,
                      overrideProps: {
                        ...commonProps
                      },
                    }),
                  [Element.heading2]: (args: any) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        ...commonProps,
                        fontSize: { base: "28px", md: "40px" },
                        lineHeight: { base: "32px", md: "40px" }
                      },
                    }),
                  [Element.heading3]: (args: any) =>
                    heading3({
                      ...args,
                      overrideProps: {
                        ...commonProps
                      },
                    }),
                  [Element.heading4]: (args: any) =>
                    heading4({
                      ...args,
                      overrideProps: {
                        ...commonProps
                      },
                    }),
                  [Element.heading5]: (args: any) =>
                    heading5({
                      ...args,
                      overrideProps: {
                        ...commonProps
                      },
                    }),
                  [Element.heading6]: (args: any) =>
                    heading6({
                      ...args,
                      overrideProps: {
                        ...commonProps
                      },
                    }),
                }}
              />
            </Box>
          </Box>
          <Flex gap={{ base: "12px", md: "40px" }} flex="1" direction={{ base: "column", md: "row" }}
          >
            <Flex align="left" direction="column" gap="10px">
              {items.map((i: any, idx: number) => renderItem(i, idx, 0))}
            </Flex>
            <Flex align="left" direction="column" gap="10px">
              {items.map((i: any, idx: number) => renderItem(i, idx, 1))}
            </Flex>
          </Flex>
        </Flex>
      </VStack>
    </BlockContainer>
  )

}

export default BlockTextualColumns;