import { FC, useEffect, useReducer, useState } from "react";
import { useLocation } from "@reach/router";
import {
  Flex,
  Box,
  Container,
} from "@chakra-ui/react";

const BlockPricingTableSection: FC<Gatsby.PageBlockPricingTableSectionFragment> = ({
  primary
}) => {
  if (!primary) throw Error();

  const {
    text,
    is_mobile_only: isMobileOnly
  } = primary;

  const location = useLocation();

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false)
  }, []);

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {

    forceUpdate();
  }, [location.search]);

  return <Container size="full"
    paddingY="0"
    position={{ base: "relative", md: "sticky" }}
    zIndex={{ base: "0", md: "20" }}
    top={{ base: "0px", md: "68px" }}
    key={update}
    filter={isBlurred ? 'blur(3px)' : ''}
    display={{ base: "flex", md: isMobileOnly ? "none" : "flex" }}
    pointerEvents="none"
  >
    <Container
      size="block"
      paddingY="0"
      textAlign="center"
      centerContent
      pointerEvents="none"
    >
      <Flex flex="1 1 0" w="full" pointerEvents="none">
        <Flex
          bg="white"
          margin="0"
          minWidth={{ base: "full", md: "252px" }}
          maxWidth={{ base: "full", md: "252px" }}
          minHeight={{ base: "80px", md: "200px" }}
          maxHeight={{ base: "80px", md: "200px" }}
          fontSize="font-20"
          lineHeight="shortest"
          color="grey-1"
          justify="flex-start"
          align={{ base: "end", md: "center" }}
          paddingBottom={{ base: "20px", md: 0 }}
          textAlign="left"
          w="full"
          borderBottom={{ base: "1px solid", md: "0px" }}
          borderColor="grey-300"
          pointerEvents="none"
        >
          {text}
        </Flex>
        <Box minWidth="calc(100% - 252px)" maxWidth="calc(100% - 252px)" display={{ base: "none", md: "block" }}
          pointerEvents="none"
        />
      </Flex>
    </Container></Container>
}

export default BlockPricingTableSection;