const getColor = (color: string) => {
  switch (color) {
    case "orange":
      return "brown-0";
    case "blue":
      return "#2A93F4";
    case "green":
      return "green-500"
    case "black":
    default:
      return "grey-0"
  }
}

export { getColor };