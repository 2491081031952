import { FC, useState, useEffect, useReducer, Fragment } from "react";
import { asText } from "@prismicio/client";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import {
  Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel, Heading, VStack, Box,
  HStack, Flex, List,
  Text,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";

import { AnimatePresence } from "framer-motion";
import { BlockContainer, IconBox, MotionBox, ShiIcon } from "src/atoms";

import { parseOneLinerLogicalText, shouldShowLogicalText } from "../utils";
import { LogicalText } from "../types";

const BlockPricingPackage: FC<Gatsby.PageBlockPricingPackageFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    navbar: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false)
  }, []);

  const location = useLocation();
  const { pathname, search } = location;

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    forceUpdate();
    setExpandedIndex(-1);
  }, [location.search]);

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const currentParams = queryString.parse(search);
  const cycle = (currentParams["cycle"] as string) ?? "";

  const isMonthly = cycle === "mensuel";

  const {
    banner,
    title_bis: title,
    subtitle_bis: subtitle,
    crossed_out_price_bis: crossedOutPrice,
    price_bis: price,
    price_subtext_bis: priceSubtext,
    is_displayed: isDisplayed,
  } = primary;

  const shouldShow = (str: string) => {
    const output: LogicalText = parseOneLinerLogicalText(str);

    return shouldShowLogicalText(pathname, search, output);
  };

  const shouldShowComponent = shouldShow(isDisplayed || '');
  if (!shouldShowComponent) {
    return null;
  }

  // TODO: rename
  // TODO: prevent duplication over other fragments
  // TODO: rename price
  const getText = (price: any) => {
    const allTexts = asText(price.raw, "\n")?.split("\n");
    const matchingLogicalText = allTexts?.find((logicalText: string) => {
      const output: LogicalText = parseOneLinerLogicalText(logicalText);

      return shouldShowLogicalText(pathname, search, output);
    });

    if (matchingLogicalText) {
      return parseOneLinerLogicalText(matchingLogicalText).text
    }

    return null;
  };

  const renderItem = (item: any, index: number) => {
    const {
      column_is_displayed: columnIsDisplayed,
      column_title_bis: columnTitle,
      multi_line_usp: multiLineUSP
    } = item;

    const shouldShowColumn = shouldShow(columnIsDisplayed);

    if (!shouldShowColumn) {
      return null;
    }

    return (
      <VStack align="left" flex="1">
        <Text
          fontSize="font-14"
          fontWeight="500"
          lineHeight="30px"
          marginBottom="10px !important"
        >{getText(columnTitle)}</Text>
        <VStack
          alignItems="start"
          spacing="space-8"
        >{asText(multiLineUSP.raw, "\n")?.split("\n").map((a: any) => {
          if (!a) {
            return null;
          }

          const output: LogicalText = parseOneLinerLogicalText(a);
          if (shouldShowLogicalText(pathname, search, output)) {
            return (
              <HStack textAlign="left" key={output.text}
              fontSize="font-14"
              lineHeight="22px"
              >
                <ShiIcon name="check" boxSize="12px" />
                <Box marginLeft="space-8">{output.text}</Box>
              </HStack>
            );
          }

          return null;
        })}</VStack>
      </VStack>
    );
  }

  return (
    <BlockContainer w="full" key={update} filter={isBlurred ? 'blur(3px)' : ''}
      paddingTop="48px !important"
      paddingBottom="0px !important"
    >
      <VStack w="full">
        {getText(banner) ? <Flex w="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="#555249"
          fontSize="font-16"
          lineHeight="22px"
          height="60px" border="1px" borderColor="#FCF0B6" borderRadius="lg" bgColor="#FCF0B6"
          marginBottom="space-8"
          paddingX={{ base: "space-8", md: 0 }}
        >
          {getText(banner) ?
            <HStack
            >
              <ShiIcon name="sparkles" boxSize="16px" /><Text>{getText(banner)}</Text>
            </HStack> : null}</Flex> : null}

        <Accordion index={expandedIndex} onChange={setExpandedIndex} textAlign="left" width="100%" allowToggle
          border="1px"
          borderRadius="lg"
          borderColor={expandedIndex === -1 ? "grey-300" : "black"}
          padding="0px"
        >
          <AccordionItem border="0px">
            {({ isExpanded }) => (
              <Fragment>
                <AccordionButton
                  display="flex"
                  bgColor="transparent"
                  justifyContent="space-between"
                  // borderBottom={expandedIndex === -1 ? "0px" : "1px"}
                  // borderColor={expandedIndex === -1 ? "grey-300" : "black"}
                  _hover={{
                    background: "transparent"
                  }}
                >
                  <Flex w="full"
                  // marginBottom={expandedIndex === -1 ? "0px" : "30px"}
                  paddingTop={{ base: 0, md: "space-16" }}
                  paddingBottom={{ base: 0, md: "space-16" }}
                  paddingLeft={{ base: 0, md: "space-16" }}
                  paddingRight="0px"
                  marginX="6px"
                  marginRight="0px"
                  marginY="12px"
                  direction={{ base: "column", md: "row" }}
                  >
                    <VStack align="left">
                      <HStack spacing="space-16">
                        <Box
                          minWidth="14px"
                          maxWidth="14px"
                          minHeight="14px"
                          maxHeight="14px"
                          backgroundColor="yellow-0"
                          borderRadius="4px"
                        />

                        <Box
                          lineHeight="34px"
                          fontSize={{ base: "28px", md: "30px" }}
                          textAlign="left"
                        >
                          {getText(title)}
                        </Box>
                      </HStack>
                      <Text
                        fontSize="font-16"
                        lineHeight="26px"
                        textAlign="left"
                      >{getText(subtitle)}</Text>
                    </VStack>
                    <VStack mr="space-16"
                      ml={{ base: 0, md: "auto" }} align="stretch">
                      <HStack ml={{ base: 0, md: "auto" }} mt={{ base: "space-16", md: 0 }}
                      >
                        {getText(crossedOutPrice) ?                         <Text
                          lineHeight="38px"
                          fontSize="18px"
                          textDecoration="line-through"
                        >
                          {getText(crossedOutPrice)}
                        </Text> : null}
                        <Text
                          lineHeight="34px"
                          fontSize={getText(crossedOutPrice) ? "26px" : "32px"}
                          
                        >
                          {getText(price)}
                        </Text>
                      </HStack>
                      <Text
                        fontSize={{ base: "14px", md: "font-16" }}
                        lineHeight="26px"
                        marginTop={{base: "4px !important", md: isMonthly ? "8px !important" : "4px !important"}}
                        textAlign="left"
                      >{getText(priceSubtext)}</Text>
                    </VStack>
                  </Flex>

                  <IconBox position="relative"
                    marginTop={{ base: 0, md: "-32px" }} 
                    _hover={{
                      bgColor: "white",
                    }}
                  >
                    <AnimatePresence>
                      <MotionBox
                        position="absolute"
                        key={isExpanded ? "minus" : "plus"}
                        initial={{ opacity: 0, rotate: 60 }}
                        animate={{ opacity: 1, rotate: 0 }}
                        exit={{ display: "none" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ShiIcon
                          name="chevron"
                          boxSize={isMobile ? "24px" : "32px"}
                          transform={`scale(1.25) ${isExpanded ? "rotate(180deg)" : "rotate(0deg)"}`}
                          color="grey-0"
                        />
                      </MotionBox>
                    </AnimatePresence>
                  </IconBox>
                </AccordionButton>

                <AccordionPanel
                  padding="0px"
                  color="text-secondary"
                  sx={{
                    b: {
                      color: "text-primary",
                    },
                    ul: {
                      marginLeft: "space-32",
                    },
                    p: {
                      _last: {
                        marginBottom: 0,
                      },
                    },
                  }}
                >
                  <Box
                    padding="16px"
                    paddingTop="28px"
                    marginTop="15px"
                    borderTop={expandedIndex === -1 ? "1px solid grey-300" : "1px solid black"}
                    color="grey-0"
                  >
                    <Flex
                    justifyContent="stretch"
                    marginX={{ base: 0, md: "space-16" }}
                    marginTop="space-8"
                    marginBottom="space-20"
                    direction={{ base: "column", md: "row" }}
                    gap={{ base: "30px", md: "0px" }}
                    >
                    {items.map((i: any, idx: number) => renderItem(i, idx))}
                    </Flex>
                  </Box>
                </AccordionPanel>
              </Fragment>
            )}
          </AccordionItem>
        </Accordion>
        <Center
          fontSize="14px"
          lineHeight="18px"
          minHeight="50px"
          maxHeight="50px"
          minWidth="50px"
          maxWidth="50px"
          border="1px"
          borderRadius="25px"
          marginTop="32px !important"
        >
          +
        </Center>
      </VStack>
    </BlockContainer>
  );
}

export default BlockPricingPackage;
