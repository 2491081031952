import { FC, useState, useEffect, useReducer } from "react";
import { ShiIcon } from "src/atoms";
import { asText, isFilled } from "@prismicio/client";
import { useLocation } from "@reach/router";
import {
  Flex,
  Box,
  Text,
  Container,
  useBreakpointValue,
} from "@chakra-ui/react";
import queryString from "query-string";
import SelfClosingTooltip from "./components/SelfClosingTooltip";

const BlockPricingTableRow: FC<Gatsby.PageBlockPricingTableRowFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    navbar: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const location = useLocation();
  const { search } = location;

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false)
  }, []);

  const indexesNotDisplayed = new URLSearchParams(search).get("idxb")?.split(",").map((x) => Number(x)) || [];
  const indexOffset = parseInt(new URLSearchParams(search).get("idxo") ?? '0', 10);

  const mobileItems = items.filter((item, idx: number) => {
    if (!item) {
      return false;
    }

    if (indexesNotDisplayed.includes(idx)) {
      return false;
    }

    return true;
  }).slice(indexOffset, indexOffset + 2);

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    forceUpdate();
  }, [location.search]);

  const currentParams = queryString.parse(search);
  const cycle = (currentParams["cycle"] as string) ?? "";

  const {
    title,
    subtitle,
    tooltip_text: tooltipText,
    badge_text: badgeText
  } = primary;

  const renderItem = (item: any, index: number) => {
    const {
      is_available: isAvailable,
      multi_line_text: multiLineText
    } = item;

    if (!isMobile && indexesNotDisplayed.includes(index)) {
      return null;
    }

    return (
      <Flex
        flex="1" align="center" justify="center"
        borderBottom="1px solid"
        borderColor="grey-300"
        key={`${title}-${subtitle}-${cycle}-${index}`}
      >
        {isFilled.richText(multiLineText?.raw)
          ? <Box
            paddingBottom="0"
            marginBottom={{ base: "16px", md: 0 }}
            >
            {asText(multiLineText.raw, "\n")?.split("\n").map((a: any) => {
              if (!a) {
                return null;
              }

              return (
                <Text key={a}>{a}</Text>
              );

            })}

          </Box>
          : <Box
          marginBottom={{ base: "16px", md: 0 }}
          ><ShiIcon name={isAvailable ? "check" : "cross"} color={isAvailable ? "grey-0" : "grey-500"} /></Box>
        }
      </Flex>
    )
  }

  return (
    <Container size="full"
      paddingY="0"
      key={update}
      filter={isBlurred ? 'blur(3px)' : ''}
    >
      <Container
        size="block"
        paddingY="0"
        textAlign="center"
        centerContent
      >
        <Flex w="full"
          gap="space-16"
          direction={{ base: "column", md: "row" }}
        >
          <Flex
            minWidth={{ base: "full", md: "252px" }}
            maxWidth={{ base: "full", md: "252px" }}
            textAlign="left"
            fontSize="font-20"
            lineHeight="shortest"
            color="grey-1"
            justify="center"
            flexDir="column"
            paddingY="space-32"
            borderBottom="1px solid"
            borderColor="grey-300"
          >
            <Flex
              align="center"
              justifyContent="space-between"
            >
              <Box marginRight="space-12"
              >{title}</Box>
              <SelfClosingTooltip tooltipText={tooltipText} />
            </Flex>
            {badgeText ? <Text
              mt="space-8"
              minWidth="0px"
              bgColor="#2A93F4"
              fontSize="font-14"
              lineHeight={1.42}
              color="white"
              px="space-12"
              py="space-8"
              borderRadius="6px"
              alignSelf="flex-start"
            >
              {badgeText}
            </Text> : null}
            {subtitle ? <Box textAlign="left"
              marginTop="space-8"
              fontSize="font-14"
              color="grey-600"
              lineHeight={1.42}
            >{subtitle}</Box> : null}
          </Flex>
          <Flex w="full">
          {(isMobile ? mobileItems : items).map((i: any, idx: number) => renderItem(i, idx))}
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
}

export default BlockPricingTableRow;