import { FC, useState, useEffect, useReducer, Fragment } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import { asText } from "@prismicio/client";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import * as DOMPurify from 'isomorphic-dompurify';
import Carousel from "src/atoms/rebrand/Carousel";
import {
  Box,
  HStack,
  Spacer,
  Text,
  VStack,
  Flex,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";

import { navigate } from "gatsby";
import { getColor } from "./utils";
import { parseOneLinerLogicalText, shouldShowLogicalText } from "../utils";
import { LogicalText } from "../types";
import Card from "./components/Card";

const BlockPricingCards: FC<Gatsby.PageBlockPricingCardsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    navbar: true,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false)
  }, []);

  const location = useLocation();
  const { pathname, search } = location;

  const currentParams = queryString.parse(search);
  const cycle = (currentParams["cycle"] as string) ?? "";

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    forceUpdate();
  }, [location.search]);

  const {
    cta_desktop: ctaDesktop,
    cta_mobile: ctaMobile,
    cta_link: ctaLink
  } = primary;

  const ctaCopy = isMobile ? ctaMobile : ctaDesktop;

  const shouldShow = (str: string) => {
    const output: LogicalText = parseOneLinerLogicalText(str);

    return shouldShowLogicalText(pathname, search, output);
  };

  const mobileItems = items.filter((item) => {
    if (!item) {
      return false;
    }

    const { is_displayed: isDisplayed } = item;

    const shouldShowCard = shouldShow(isDisplayed || '');
    return shouldShowCard;
  });

  // TODO: rename renderPrice
  const renderPrice = (price: any, props = {}, shouldSanitize = false, onlyText = false) => {
    const allTexts = asText(price.raw, "\n")?.split("\n");
    const matchingLogicalText = allTexts?.find((logicalText: string) => {
      const output: LogicalText = parseOneLinerLogicalText(logicalText);

      return shouldShowLogicalText(pathname, search, output);
    });

    if (matchingLogicalText) {
      if (shouldSanitize) {
        const canSanitize = DOMPurify.isSupported;
        if (!canSanitize) { return null; }
        const textSanitized = DOMPurify.sanitize(parseOneLinerLogicalText(matchingLogicalText).text || "", { USE_PROFILES: { html: true } });
        return <Box
          {...props}
          dangerouslySetInnerHTML={{ __html: textSanitized }}
        />
      }

      if (onlyText) {
        return parseOneLinerLogicalText(matchingLogicalText).text;
      }

      return <Box
        {...props}
      >{parseOneLinerLogicalText(matchingLogicalText).text}</Box>
    }

    return null;
  };

  return (
    <BlockContainer key={update} filter={isBlurred ? 'blur(3px)' : ''}
      // border="1px solid pink"
    >
      <Flex alignItems='stretch' gap={{ base: "space-4", lg: "space-16" }}
        display={{ base: "none", md: "flex" }}
      >
        {items.map((item, idx: number) => (
          <Card key={`${item?.plan_name}-${idx}-${cycle}`} item={item} />
        ))}
      </Flex>
      <Carousel
        display={{ base: "block", md: "none" }}
        indicators="floating-arrows"
        carouselWrapperProps={{
          position: "relative",
          paddingTop: 0,
        }}
        // breakPointValue={{ base: 1, md: 1, lg: 1 }}
        items={mobileItems.map((item, idx: number) => (
          <Card key={`${item?.plan_name}-${idx}-${cycle}`} item={item} margin="0" />
        ))}
      />
      {renderPrice(ctaCopy) ? <Box marginBottom="space-48" /> : null}
      {renderPrice(ctaCopy) ? <Button href={ctaLink?.url} fontWeight={{ base: "500", md: "400" }} onClick={() => {
        if (ctaLink?.url?.startsWith('#')) {
          const anchor = ctaLink?.url?.slice(1);
          const anchorDocument = document.getElementById(anchor);

          if (anchorDocument) {
            anchorDocument.scrollIntoView({ behavior: "smooth" });

            // Change the URL to match the section's link.
            history.pushState(null, "", `#${anchor}`);
          }
        }

        else {
          navigate(ctaLink?.url);
        }
      }} variant={isMobile ? "secondary" : "primary"}
      borderBottom="1px solid"
      paddingBottom={{ base: "space-8", md: "space-16" }}
      >
        {renderPrice(ctaCopy)}
      </Button> : null}
    </BlockContainer>
  );
}

export default BlockPricingCards;