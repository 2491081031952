import { FC, useEffect, useState, useReducer } from "react";
import { asText } from "@prismicio/client";
import { navigate } from "gatsby";
import {
  Flex,
  Box,
  HStack,
  Container,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { getColor } from "./utils";
import { LogicalText } from "../types";
import { useLocation } from "@reach/router";
import { parseOneLinerLogicalText, shouldShowLogicalText } from "../utils";
import queryString from "query-string";
import CarouselArrow from "src/atoms/rebrand/Carousel/components/CarouselArrow";
import { onClickNewAccountNewPricing } from "src/lib/event";
import { ClickInfoNewPricing } from "src/lib/event/types";
import patchSignupURL, { isSignupURL } from "src/atoms/PrismicLink/lib/patchSignupURL";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";

const BlockPricingTableHeader: FC<Gatsby.PageBlockPricingTableHeaderFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    navbar: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const visitorContext = useVisitorContext();
  const location = useLocation();
  const { pathname, search } = location;
  const currentParams = queryString.parse(search);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const cycle = (currentParams["cycle"] as string) ?? "";

  useEffect(() => {
    if (currentParams["idxo"] !== carouselIndex.toString()) {
      currentParams["idxo"] = carouselIndex.toString();
      navigate(`?${queryString.stringify(currentParams)}`, { replace: true });
    }
  }, [carouselIndex]);

  useEffect(() => {
    setCarouselIndex(0);
  }, [cycle]);

  const navigateToUpdatedIdxb = (index: number) => {
    const currentParams = queryString.parse(search);
    const indexesStr = (currentParams["idxb"] as string) || "";
    const indexesNotDisplayed = indexesStr.split(",").filter((x) => x !== "").sort((a, b) => Number(a) - Number(b));
    if (!indexesNotDisplayed.includes(`${index}`)) {
      const newIndexesStr = [`${index}`, ...indexesNotDisplayed].filter((x) => x !== "").sort((a, b) => Number(a) - Number(b)).join(',');
      currentParams["idxb"] = newIndexesStr;
      // TODO: if more than one column being hidden, it could create multiple successive redirections as opposed to having them batched
      navigate(`?${queryString.stringify(currentParams)}`, { replace: true });
    }
  }

  const shouldShow = (str: string) => {
    const output: LogicalText = parseOneLinerLogicalText(str);

    return shouldShowLogicalText(pathname, search, output);
  };

  // Running the logic on is_displayed is important so that idxb can properly be updated
  const mobileItems = items.filter((item, index: number) => {
    if (!item) {
      return false;
    }

    const { is_displayed: isDisplayed } = item;

    // This piece keeps in sync the table header and the table rows for mobile
    if (!shouldShow(isDisplayed)) {
      navigateToUpdatedIdxb(index);

      return false;
    }

    return true;
  });

  const mobileItemsBeingDisplayed = mobileItems.slice(carouselIndex, carouselIndex + 2);

  const {
    initial_section: initialSection,
  } = primary;

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false)
  }, []);

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    forceUpdate();
  }, [location.search]);

  const isYearly = cycle === "annuel";

  const getText = (price: any) => {
    const allTexts = asText(price.raw, "\n")?.split("\n");
    const matchingLogicalText = allTexts?.find((logicalText: string) => {
      const output: LogicalText = parseOneLinerLogicalText(logicalText);

      return shouldShowLogicalText(pathname, search, output);
    });

    if (matchingLogicalText) {
      return parseOneLinerLogicalText(matchingLogicalText).text
    }

    return null;
  };

  const renderSection = () => {
    return (
      <Flex
        bg="white"
        margin="0"
        minWidth={{ base: "full", md: "252px" }}
        maxWidth={{ base: "full", md: "252px" }}
        minHeight={{ base: "80px", md: "200px" }}
        maxHeight={{ base: "80px", md: "200px" }}
        fontSize="font-20"
        lineHeight="shortest"
        color="grey-1"
        justify="flex-start"
        align="center"
        textAlign="left"
        w="full"
      >
        {initialSection}
      </Flex>
    );
  }

  const renderItem = (item: any, index: number) => {
    const {
      is_displayed: isDisplayed,
      color,
      plan_name_string: planName,
      price_line_for_monthly: priceLineForMonthly,
      price_line_for_yearly: priceLineForYearly,
      cta_text_desktop_bis: ctaTextDesktop,
      cta_text_mobile_bis: ctaTextMobile,
      cta_link: ctaLink
    } = item;

    // This piece keeps in sync the table header and the table rows for desktop
    if (!shouldShow(isDisplayed)) {
      navigateToUpdatedIdxb(index);

      return null;
    }

    return (
      <Flex
        flex="1"
        key={`${planName}-${index}-${cycle}`}
        bg="white"
        borderColor="#E0E0E0 !important"
        borderRadius="lg"
        border="1px"
        paddingX={{ base: "space-8", md: "space-8", xl: "space-32" }}
        paddingY={{ base: "space-16", md: "space-32" }}
        flexDir="column"
        textAlign="left"
      >
        <HStack spacing={{ base: "space-8", md: "space-16" }}>
          <Box
            minWidth="14px"
            maxWidth="14px"
            minHeight="14px"
            maxHeight="14px"
            backgroundColor={getColor(color)}
            borderRadius="4px"
          />

          <Box
            lineHeight={{ base: "base", md: "shortest" }}
            fontSize={{ base: "font-20", md: "font-24" }}
          >
            {planName}
          </Box>
        </HStack>
        <Box
          lineHeight={{ base: "base", md: "shortest" }}
          fontSize={{ base: "font-20", md: "font-24" }}
          marginTop={{ base: "space-2", md: "space-4" }}
        >{isYearly ? priceLineForYearly : priceLineForMonthly}</Box>
        <Button
          marginTop={{ base: "12px", md: "30px" }}
          textAlign="center"
          fontSize="font-14"
          backgroundColor="grey-0"
          borderRadius="65px"
          paddingX="8px"
          paddingY="8px"
          color="white"
          lineHeight="shorter"
          _hover={{
            color: "white",
            backgroundColor: "grey-700"
          }}
          onClick={(e) => {
            const clickInfoNewPricing: ClickInfoNewPricing = {
              label: getText(isMobile ? ctaTextMobile : ctaTextDesktop) || '',
              platform: isMobile ? 'web-mobile' : 'web-desktop',
              block: 'block_pricing_table_header',
              fromLocation: location,
              plan: planName,
              toUrl: isSignupURL(ctaLink?.url) ? patchSignupURL(visitorContext, ctaLink?.url) : ctaLink?.url
            };

            onClickNewAccountNewPricing(e, clickInfoNewPricing)
          }}
        >
          {getText(isMobile ? ctaTextMobile : ctaTextDesktop)}
        </Button>
      </Flex>
    )
  }

  return (
    <Container size="full"
      paddingY="0"
      position="sticky"
      zIndex="20"
      top={{ base: "65px", md: "68px" }}
      key={update}
      filter={isBlurred ? 'blur(3px)' : ''}
    >
      <Container
        size="block"
        paddingY="0"
        textAlign="center"
        centerContent
      >
        <Flex w="full" gap={{ base: "space-8", md: "space-16" }} direction={{ base: "column", md: "row" }} bgColor="white">
          {isMobile ? null : renderSection()}
          <Flex w="full" gap={{ base: "space-8", md: "space-16" }}>
            {(isMobile ? mobileItemsBeingDisplayed : items).map((i: any, idx: number) => renderItem(i, idx))}
          </Flex>
          <Flex justifyContent="space-between" w="full"
            direction="row"
            display={{ base: "flex", md: "none" }}
            mb="space-8"
          >
            <CarouselArrow
              aria-label="prev"
              disabled={carouselIndex <= 0}
              useAlternateDesign={true}
              onClick={() => {
                setCarouselIndex(carouselIndex - 1)
              }}
              transform="rotate(180deg)"
            />
            <CarouselArrow
              disabled={carouselIndex + 2 >= mobileItems.length}
              aria-label="next"
              useAlternateDesign={true}
              onClick={() => {
                setCarouselIndex(carouselIndex + 1)
              }}
            />
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
}

export default BlockPricingTableHeader;