import { FC, useState, useEffect, useReducer } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import { asText } from "@prismicio/client";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import {
  Box,
  Heading,
  HStack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";

import { navigate } from "gatsby";
import { parseOneLinerLogicalText, shouldShowLogicalText } from "../utils";
import { LogicalText } from "../types";
import { heading1 } from "src/lib/renderer/elements/headings";
import { Element } from "@prismicio/react";

const BlockTabs: FC<Gatsby.PageBlockTabsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  const navigateToCycle = (cycleValue: string) => {
    const currentParams = queryString.parse(search);
    currentParams["cycle"] = cycleValue;
    delete currentParams["idxb"]; // blocklist used for both desktop and mobile
    delete currentParams["idxo"]; // offset used for mobile only
    navigate(`?${queryString.stringify(currentParams)}`, { replace: true });
  };

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    navbar: true,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const location = useLocation();
  const { pathname, search } = location;

  const [isBlurred, setIsBlurred] = useState(true);
  useEffect(() => {
    setIsBlurred(false);

    if (!search.includes('cycle=')) {
      navigateToCycle("annuel");
    }
  }, []);

  useEffect(() => {
    if (!search.includes('cycle=')) {
      navigateToCycle("annuel")
    }
  }, [search]);

  // Note: because of Gatsby internal behavior ignoring location.search changes, we force update
  const [update, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    forceUpdate();
  }, [location.search]);

  const currentParams = queryString.parse(search);
  const cycle = (currentParams["cycle"] as string) ?? "";

  const {
    content,
    alternative_h1_title: alternativeH1Title,
  } = primary;

  const isMonthly = cycle === "mensuel";
  const isYearly = cycle === "annuel";

  // TODO: prevent duplication over other fragments
  const getText = (richText: any) => {
    const allTexts = asText(richText.raw, "\n")?.split("\n");
    const matchingLogicalText = allTexts?.find((logicalText: string) => {
      const output: LogicalText = parseOneLinerLogicalText(logicalText);

      return shouldShowLogicalText(pathname, search, output);
    });

    if (matchingLogicalText) {
      return parseOneLinerLogicalText(matchingLogicalText).text
    }

    return null;
  };

  const renderItem = (item: any, index: number) => {
    const {
      text_desktop: textDesktop,
      text_mobile: textMobile,
      url_parameter_value: urlParameterValue
    } = item;

    const text = getText(isMobile ? textMobile : textDesktop);

    const itemIsMonthly = index === 0;
    const itemIsYearly = index === 1;

    const itemMatchesContext = (itemIsMonthly && isMonthly) || (itemIsYearly && isYearly);

    return (
      <Box
        key={`${index}`}
        cursor="pointer"
        textColor={itemMatchesContext ? "white" : "grey-0"}
        backgroundColor={itemMatchesContext ? "grey-0" : "white"}
        borderRadius="100px"
        margin="space-4"
        fontSize={{ base: "14px", md: "16px" }}
        paddingY="9px"
        paddingX="space-16"
        onClick={() => {
          if (itemMatchesContext) return;
          navigateToCycle(urlParameterValue);
        }}>
        {text}
      </Box>
    )
  }

  return (
    <BlockContainer key={update} filter={isBlurred ? 'blur(3px)' : ''}
      paddingBottom="0px !important" >
      <VStack>
        {!getText(alternativeH1Title) ? <Renderer
          field={content}
          overrides={{
            [Element.heading1]: (args) =>
              heading1({
                ...args,
                overrideProps: {
                  variant: "heading-1",
                  fontSize: "48px",
                  lineHeight: "58px"
                },
              })
          }}
        /> : null}
        {getText(alternativeH1Title) ? <Heading
          as="h1"
          variant="heading-1"
          fontSize={{ base: "42px", md: "48px" }}
          lineHeight="58px"
          marginBottom="22px"
        >
          {getText(alternativeH1Title)}
        </Heading> : null}
        <HStack
          border="1px"
          borderColor="grey-300"
          borderRadius="100px"
          paddingY="space-0"
          paddingRight="space-4"
          lineHeight={1.375}
          fontSize="font-16"
        >
          {items.map((i: any, idx: number) => renderItem(i, idx))}
        </HStack>
      </VStack>
    </BlockContainer>
  );
}

export default BlockTabs;
